import React, { useEffect, useState } from "react";
import "./AuctionBidHall.scss";
import { commaFormat, formatDate, formatDateTime } from "../../utils/Helper";
import BidConfirmationPopup from "../BidConfirmationPopup/BidConfirmationPopup";
import XetModal from "../shared/xet-modal/XetModal";
import {
  Auction,
  AuctionBidStatus,
  BidMapAddress,
  BidMapResponse,
} from "../../models/Auction";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import VendorAuctionResult from "../VendorAuctionResult/VendorAuctionResult";
import VendorBidHistoryReport from "../VendorBidHistoryReport/VendorBidHistoryReport";

interface AuctionBidHallProps {
  auction: Auction;
  bidMap: BidMapResponse;
}

const AuctionBidHall: React.FC<AuctionBidHallProps> = ({ auction, bidMap }) => {
  const [loadingItemId, setLoadingItemId] = useState<number>(-1);
  const [confirmationUniquePopupId, setConfirmationUniquePopupId] = useState<
    number | null
  >(null);
  const [timeLeft, setTimeLeft] = useState("");
  const [bids, setBids] = useState<BidMapResponse>(bidMap);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [unitRates, setUnitRates] = useState<{ [key: number]: number }>({});

  useEffect(() => {
    const updateTimer = () => {
      if (auction) {
        const endTime = new Date(auction.end_time).getTime();
        const now = new Date().getTime();
        const timeDifference = endTime - now;

        if (timeDifference > 0) {
          const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
          );
          setTimeLeft(`${days}d ${hours}h ${minutes}m Left`);
        } else {
          setTimeLeft("Auction Ended");
        }
      }
    };
    updateTimer();
    const intervalId = setInterval(updateTimer, 60000);
    return () => clearInterval(intervalId);
  }, [auction]);

  const handleBidClick = (sku_id: number) => {
    setLoadingItemId(sku_id);
    setConfirmationUniquePopupId(sku_id);
    setShowConfirmationPopup(true);
  };
  const saveBid = async () => {
    if (confirmationUniquePopupId) {
      try {
        const { data } = await ApiService().client.post(
          URL.AUCTION.ADD_BID(auction.auction_id),
          {
            price: unitRates[confirmationUniquePopupId],
            sku_id: confirmationUniquePopupId,
            invite_id: auction.invite_id,
          }
        );
        if (data) {
          setBids(data.bids);
          setLoadingItemId(-1);
        }
      } catch (e) {
        console.error("Error:", e);
      }
    }
  };

  const handleUnitRateChange = (sku_id: number, value: string) => {
    setUnitRates((prev) => {
      return { ...prev, [sku_id]: parseFloat(value) };
    });
  };

  return (
    <div className="flex-column gap-8">
      <div className="bid-hall-wrapper p-12">
        <div className="upper-detail-container flex-row align-items-center justify-content-space-between px-8 py-8 xetgo-font-tag border-box bold">
          <p style={{ color: "#5151EC" }} className="cursor-pointer">
            {timeLeft}
          </p>
          {auction.auction_status === "PAST" && (
            <div className="flex-row align-items-center gap-16">
              <VendorAuctionResult auction={auction} bids={bids} />
              <VendorBidHistoryReport auction={auction} bids={bids} />
            </div>
          )}
        </div>
        <div className="flex-column gap-2">
          <div className="history-bid-hall-main-container">
            {Object.values(bids).length > 0 && (
              <div className="all-bids-main-container flex-column gap-8">
                {Object.values(bids).map(
                  (details: BidMapAddress, detailIndex) => (
                    <div key={detailIndex} className="bid-detail-container">
                      <div className="address-bar p-8 flex-row gap-16 xetgo-font-tag">
                        <p className="district bold">{details.state}</p>
                        <p className="line">{details.name}</p>
                      </div>
                      <div className="all-sku-detail-container flex-column">
                        {details.skus.map((skuDetail, skuIndex) => (
                          <div
                            key={detailIndex}
                            className={`sku-expansion-wrapper-container ${
                              detailIndex === 0 && "border-top-none"
                            } ${
                              details.skus.length - 1 === detailIndex &&
                              "border-bottom-none-raidus"
                            }`}
                          >
                            <div className="expanded-main-container">
                              <div className="expanded-heading-container flex-row align-items-center xetgo-font-tag">
                                <div className="flex-row align-items-center flex-1">
                                  <p className="flex-row align-items-center px-12 py-8">
                                    {skuIndex + 1}
                                  </p>
                                  <p className="flex-row align-items-center px-12 py-8">
                                    {skuDetail.name}
                                  </p>
                                </div>
                                <div className="flex-row gap-20">
                                  <p className="flex-row align-items-center gap-12 px-12 py-8">
                                    <span>Quantity</span>{" "}
                                    <span className="flex-row align-items-center gap-6">
                                      <span style={{ color: "#1F2937" }}>
                                        {commaFormat(
                                          skuDetail.quantity.toString()
                                        )}
                                      </span>{" "}
                                      <span style={{ color: "#6B7280" }}>
                                        {skuDetail.unit}
                                      </span>
                                    </span>
                                  </p>
                                  <p className="flex-row align-items-center gap-12 px-12 py-8">
                                    Start Price{" "}
                                    <span style={{ color: "#1F2937" }}>
                                      ₹{" "}
                                      {commaFormat(
                                        skuDetail.start_price.toString()
                                      )}
                                    </span>
                                  </p>
                                  <p className="flex-row align-items-center gap-12 px-12 py-8">
                                    Decrement{" "}
                                    <span style={{ color: "#1F2937" }}>
                                      ₹{" "}
                                      {commaFormat(
                                        skuDetail.decreement.toString()
                                      )}
                                    </span>
                                  </p>
                                  <p className="flex-row align-items-center gap-12 px-12 py-8">
                                    Rank{" "}
                                    <span style={{ color: "#1F2937" }}>
                                      {skuDetail.bids[0]?.bid_rank}
                                    </span>
                                  </p>
                                  <p className="flex-row align-items-center gap-12 px-12 py-8">
                                    Bid Value{" "}
                                    <span style={{ color: "#1F2937" }}>
                                      {skuDetail.bids.length > 0
                                        ? `₹ ${commaFormat(
                                            skuDetail.bids[0]?.price.toString()
                                          )}`
                                        : "-"}
                                    </span>
                                  </p>
                                </div>
                              </div>

                              {auction.auction_status === "LIVE" && (
                                <div className="bidhall-editing-bid-main-container flex-row align-items-center justify-content-space-between py-8  px-12">
                                  <div className="flex-row align-items-center gap-26">
                                    <div className="flex-row gap-14 align-items-center">
                                      <p className="xetgo-font-tag">
                                        Bid Value
                                      </p>
                                      <input
                                        className="editing-unit border-box xetgo-font-tag px-12 py-6"
                                        type="number"
                                        key={detailIndex}
                                        value={unitRates[skuDetail.id]}
                                        placeholder="Enter Amount"
                                        onChange={(e) =>
                                          handleUnitRateChange(
                                            skuDetail.id,
                                            e.target.value || ""
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="flex-row align-items-center gap-26 xetgo-font-tag">
                                      <span>Total Value</span>{" "}
                                      {unitRates[skuDetail.id] *
                                        skuDetail.quantity >
                                      0 ? (
                                        <span style={{ color: "#5151EC" }}>
                                          <span
                                            className="bolder"
                                            style={{ color: "#5151EC" }}
                                          >
                                            ₹{" "}
                                            {commaFormat(
                                              `${
                                                unitRates[skuDetail.id] *
                                                skuDetail.quantity
                                              }`
                                            )}
                                          </span>
                                        </span>
                                      ) : (
                                        "-"
                                      )}
                                    </div>
                                  </div>
                                  <button
                                    onClick={() => handleBidClick(skuDetail.id)}
                                    disabled={
                                      unitRates[skuDetail.id] == null ||
                                      isNaN(unitRates[skuDetail.id])
                                    }
                                    className={`bid-btn xetgo-font-tag border-box flex-row align-items-center  justify-content-center px-24 py-6 cursor-pointer ${
                                      (unitRates[skuDetail.id] == null ||
                                        isNaN(unitRates[skuDetail.id])) &&
                                      "bid-btn-disabled"
                                    }`}
                                  >
                                    {loadingItemId === skuIndex ? (
                                      <p className="spinner-icon"></p>
                                    ) : (
                                      "Bid"
                                    )}
                                  </button>

                                  {showConfirmationPopup &&
                                    confirmationUniquePopupId ===
                                      skuDetail.id && (
                                      <XetModal
                                        backdropClose={true}
                                        open={showConfirmationPopup}
                                        handleClose={() =>
                                          setShowConfirmationPopup(false)
                                        }
                                      >
                                        <BidConfirmationPopup
                                          key={detailIndex}
                                          saveBid={saveBid}
                                          handleClose={() =>
                                            setShowConfirmationPopup(false)
                                          }
                                          unitRate={
                                            unitRates[confirmationUniquePopupId]
                                          }
                                          totalRate={
                                            Math.round(
                                              skuDetail.quantity *
                                                unitRates[
                                                  confirmationUniquePopupId
                                                ] *
                                                100
                                            ) / 100
                                          }
                                        />
                                      </XetModal>
                                    )}
                                </div>
                              )}

                              <div className="heading-container-bid-hall-history bidder-grid ">
                                <p className="flex-row align-items-center grid-border-right px-12 py-8 normal-unit border-box xetgo-font-tag ">
                                  Unit Rate
                                </p>
                                <p className="flex-row align-items-center grid-border-right p-12 normal-unit border-box xetgo-font-tag">
                                  Total Rate
                                </p>

                                <p className="flex-row align-items-center grid-border-right p-12  normal-unit border-box xetgo-font-tag ">
                                  Status
                                </p>
                                <p className="flex-row align-items-center grid-border-right p-12 normal-unit border-box xetgo-font-tag ">
                                  Bid Time
                                </p>
                                <p className="flex-row align-items-center grid-border-right p-12 normal-unit border-box xetgo-font-tag ">
                                  Remarks
                                </p>
                              </div>
                              {skuDetail.bids.length > 0 && (
                                <div className="all-bidder-container">
                                  {skuDetail.bids.map((bid, bidderIndex) => (
                                    <div key={bidderIndex} className="">
                                      <div className="bidder-detail-container bidder-grid xetgo-font-tag">
                                        <p className="flex-row align-items-center grid-border-right px-12 py-8">
                                          <span className="normal-unit px-12 py-5 border-box xetgo-font-tag">
                                            ₹{" "}
                                            {commaFormat(bid.price.toString())}
                                          </span>
                                        </p>

                                        <p className="flex-row align-items-center gap-12 grid-border-right px-12 py-10">
                                          ₹{" "}
                                          <span>
                                            {commaFormat(
                                              (
                                                Math.round(
                                                  skuDetail.quantity *
                                                    bid.price *
                                                    100
                                                ) / 100
                                              ).toString()
                                            )}
                                          </span>
                                        </p>

                                        <p
                                          className={`flex-row align-items-center grid-border-right px-12 py-10 ${
                                            bid.status === "REJECTED" &&
                                            "rejected"
                                          }`}
                                        >
                                          <span
                                            className={`bid-status  px-12 py-4 flex-row align-items-center`}
                                            style={{
                                              color:
                                                AuctionBidStatus[bid.status]
                                                  .color,
                                              backgroundColor:
                                                AuctionBidStatus[bid.status]
                                                  .background,
                                              border: `1px solid ${
                                                AuctionBidStatus[bid.status]
                                                  .border
                                              }`,
                                            }}
                                          >
                                            {AuctionBidStatus[bid.status].name}
                                          </span>
                                        </p>

                                        <p
                                          style={{ color: "#B0B0B0" }}
                                          className="flex-row align-items-center grid-border-right px-12 py-10"
                                        >
                                          {formatDateTime(bid.created_at)}
                                        </p>
                                        <p
                                          style={{ color: "#636363" }}
                                          className="flex-row align-items-center grid-border-right px-12 py-10"
                                        >
                                          {bid.remark}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                              {skuDetail.bids.length === 0 && (
                                <p
                                  style={{ color: "#6B7280" }}
                                  className="no-bid-state flex-row align-items-center justify-content-center xetgo-font-tag bold p-12"
                                >
                                  No bids received{" "}
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="below-warning-main-container flex-row gap-12 px-16 py-16 xetgo-font-tag">
        <div className="alert-icon-container flex-row align-items-center justify-content-center">
          <img
            height={28}
            width={48}
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241030053829-Group+1171276135.svg"
            alt="alert"
          />
        </div>
        <p>
          To ensure a smooth bidding process, it is strongly recommended to
          submit your bid well in advance. Last-minute submissions may face
          complications such as internet issues, network disruptions, system
          crashes, or power outages. Please note that neither the department nor
          Digifac (the service provider) will be held responsible for any delays
          or unforeseen circumstances arising from late submissions.
        </p>
      </div>
    </div>
  );
};

export default AuctionBidHall;
