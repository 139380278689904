import React, { useEffect, useRef, useState } from "react";
import "./AuctionDetail.scss";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Auction, BidMapResponse, IAuctionBid } from "../../models/Auction";
import AuctionDetailInfo from "../../components/AuctionDetailInfo/AuctionDetailInfo";
import { useToast } from "../../hooks/useToast";
import AuctionVendorForm from "../AuctionVendorForm/AuctionVendorForm";
import CommentCard from "../../components/CommentSection/CommentCard/CommentCard";
import { getFileIcon } from "../../utils/Helper";
import AuctionBidHall from "../../components/AuctionBidHall/AuctionBidHall";
import BidHallTermsAndCondition from "../../components/BidHallTermsAndCondition/BidHallTermsAndCondition";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";
import VendorBidHistoryReport from "../../components/VendorBidHistoryReport/VendorBidHistoryReport";
import VendorAuctionResult from "../../components/VendorAuctionResult/VendorAuctionResult";

const AuctionDetail: React.FC = () => {
  const [activeTab, setActiveTab] = useState("DETAIL");
  const user = useSelector(userDetails);
  const navigate = useNavigate();
  const location = useLocation();
  const [auction, setAuction] = useState<Auction>();
  const [bids, setBids] = useState<BidMapResponse>([]);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const toast = useToast();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [newComment, setNewComment] = useState("");
  const [timeLeft, setTimeLeft] = useState("");
  const [lastTab, setLastTab] = useState<string | null>(null);

  useEffect(() => {
    const updateTimer = () => {
      if (auction) {
        const endTime = new Date(auction.end_time).getTime();
        const now = new Date().getTime();
        const timeDifference = endTime - now;

        if (timeDifference > 0) {
          const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
          );
          setTimeLeft(`${days}d ${hours}h ${minutes}m Left`);
        } else {
          setTimeLeft("Auction Ended");
        }
      }
    };
    updateTimer();
    const intervalId = setInterval(updateTimer, 60000);
    return () => clearInterval(intervalId);
  }, [auction]);

  useEffect(() => {
    fetchAuctionDetails();
    fetchBids();
  }, [id]);

  useEffect(() => {
    const tab = searchParams.get("tab");

    if (tab !== "bid-hall") {
      setLastTab(tab);
    }
    if (tab === "vendor-form") {
      setActiveTab("VENDOR_FORM");
    } else if (tab === "bid-hall") {
      setActiveTab("BID_HALL");
    } else if (tab === "report") {
      setActiveTab("REPORT");
    } else if (tab === "comments") {
      setActiveTab("COMMENT");
    } else {
      setActiveTab("DETAIL");
    }
  }, [location, lastTab]);

  const fetchAuctionDetails = async () => {
    try {
      const { data } = await ApiService().client.get(
        URL.AUCTION.DETAILS(id || "")
      );
      if (data) {
        setAuction(data.auction);
      }
    } catch (e) {
      console.log("Error:", e);
    }
  };

  const fetchBids = async () => {
    try {
      const { data } = await ApiService().client.get(
        URL.AUCTION.BIDS(id || "")
      );
      if (data) {
        setBids(data.bids);
      }
    } catch (e) {
      console.log("Error:", e);
    }
  };

  const openBidHall = () => {
    navigate(`/auctions/${id}?tab=bid-hall`);
    // toast.info(
    //   "Bid Opening Error",
    //   "Bidding is not accessible at this moment. It will be available once the auction is live."
    // );
  };

  const deleteFile = (file: File) => {
    setSelectedFiles((old) => old.filter((row) => row.name !== file.name));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      setSelectedFiles(files);
    }
  };

  const handleCommentSubmit = async () => {
    if (newComment.length <= 0 && selectedFiles.length <= 0 && auction) {
      return;
    }
    try {
      if (auction) {
        const formData = new FormData();
        formData.append("comment", newComment);
        formData.append("id", id || "");
        formData.append(
          "auction_vendor_invitation_id",
          auction.invite_id.toString()
        );
        if (selectedFiles.length > 0) {
          selectedFiles.forEach((file) => {
            formData.append("files[]", file);
            formData.append("displayNames[]", file.name);
          });
        }
        const { data } = await ApiService().client.post(
          URL.AUCTION.VENDOR_COMMENT(id || "", user.company_id || -1),
          formData
        );
        setNewComment("");
        setSelectedFiles([]);
        setAuction((val) => {
          if (val) {
            return { ...val, comments: [...val.comments, data.comment] };
          }
          return val;
        });
      }
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  const updateStatus = async (status: string) => {
    try {
      const { data } = await ApiService().client.put(
        URL.AUCTION.STATUS(id || ""),
        {
          status,
        }
      );
      if (data) {
        setAuction((old) => {
          if (old) {
            return {
              ...old,
              status,
            };
          }
          return old;
        });
      }
    } catch (e) {
      console.error("Error:", e);
    }
  };

  const handleNavigateToLastTab = () => {
    if (lastTab) {
      navigate(`/auctions/${id}?tab=${lastTab}`);
    } else {
      navigate(`/auctions/${id}`);
    }
  };

  const acceptTermCondition = async () => {
    try {
      const { data } = await ApiService().client.put(
        URL.AUCTION.VENDOR(id || "", user.company_id || -1),
        {
          t_c: true,
        }
      );
      if (data) {
        setAuction((prev) => {
          if (prev) {
            return { ...prev, t_c: true };
          }
          return prev;
        });
      }
    } catch (e) {
      console.log("Error:", e);
    }
  };

  return (
    <div className="auction-detail-main-page full-width flex-column">
      <div className="upper-bar flex-row align-items-center justify-content-space-between py-12 px-16">
        <p className="xetgo-font-tag">
          <span
            className="sub-title cursor-pointer"
            onClick={() => navigate("/auctions")}
          >
            Auctions /
          </span>{" "}
          <span
            className={`${
              activeTab !== "DETAIL" && "sub-title"
            } cursor-pointer`}
            onClick={() => navigate(`/auctions/${id}`)}
          >
            {auction?.title}
          </span>
          {activeTab !== "DETAIL" && <span className="sub-title"> / </span>}
          {activeTab === "VENDOR_FORM" && <span>Form</span>}
          {activeTab === "BID_HALL" && <span> Bid Hall</span>}
          {activeTab === "REPORT" && <span>Reports</span>}
          {activeTab === "COMMENT" && <span>Comments</span>}
        </p>
      </div>
      <div
        className="auction-status-container flex-row justify-content-space-between px-16 py-10"
        style={{ paddingBottom: "0px" }}
      >
        <div className="type-btn xetgo-font-tag bold px-18 py-6">
          Reverse Auction
        </div>
        {(auction?.status == null || auction?.status == "INVITED") && (
          <div className="flex-row gap-8">
            <div
              className="decline-btn xetgo-font-tag bold py-6 cursor-pointer"
              onClick={() => updateStatus("DECLINED")}
            >
              Decline
            </div>
            <div
              className="join-btn xetgo-font-tag bold py-6 cursor-pointer"
              onClick={() => updateStatus("ACCEPTED")}
            >
              Join
            </div>
          </div>
        )}
        {auction?.status == "ACCEPTED" && (
          <div className="accepted-btn xetgo-font-tag bold py-6 cursor-pointer px-24">
            Accepted
          </div>
        )}
        {auction?.status == "DECLINED" && (
          <div className="declined-btn xetgo-font-tag bold py-6 cursor-pointer px-24">
            Declined
          </div>
        )}
        {auction?.status == "PENDING_APPROVAL" && (
          <div className="pending-btn xetgo-font-tag bold py-6 cursor-pointer px-24">
            Pending Approval
          </div>
        )}
        {auction?.status == "APPROVED" && (
          <div className="accepted-btn xetgo-font-tag bold py-6 cursor-pointer px-24">
            Approved
          </div>
        )}
        {auction?.status == "REJECTED" && (
          <div className="declined-btn xetgo-font-tag bold py-6 cursor-pointer px-24">
            Rejected
          </div>
        )}
        {auction?.status == "EDIT_REQUESTED" && (
          <div className="edit-request-btn xetgo-font-tag bold py-6 cursor-pointer px-24">
            Edit Requested
          </div>
        )}
      </div>
      <div className="auction-detail-lower-main-container px-16 py-10 flex-column gap-12">
        <div className="auction-detail-tab-main-container flex-row align-items-center xetgo-font-button">
          <div
            onClick={() => navigate(`/auctions/${id}`)}
            className={`tab-container cursor-pointer border-box flex-row align-items-center gap-8 px-12 py-8 flex-row align-items-center justify-content-center ${
              activeTab == "DETAIL" && "activeTab"
            }`}
          >
            Auction Details
          </div>
          <div
            onClick={() => navigate(`/auctions/${id}?tab=vendor-form`)}
            className={`tab-container border-box flex-row align-items-center gap-8 px-12 py-8 flex-row align-items-center justify-content-center        cursor-pointer ${
              activeTab == "VENDOR_FORM" && "activeTab"
            }`}
          >
            Vendor Detail Form
          </div>
          <div
            onClick={openBidHall}
            className={`tab-container border-box flex-row align-items-center gap-8 px-12 py-8 flex-row align-items-center justify-content-center cursor-pointer ${
              activeTab == "BID_HALL" && "activeTab"
            }`}
          >
            {auction?.auction_status === "UPCOMING" && (
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241128113156-lock.svg"
                alt="lock"
                height={14}
                width={14}
              />
            )}
            <p>Bid Hall</p>
          </div>
          <div
            onClick={() => navigate(`/auctions/${id}?tab=comments`)}
            className={`tab-container border-box flex-row align-items-center gap-8 px-12 py-8 flex-row align-items-center justify-content-center cursor-pointer ${
              activeTab == "COMMENT" && "activeTab"
            }`}
          >
            Comments
          </div>
        </div>
        <div className="flex-1 overflow-scroll">
          {activeTab === "DETAIL" && auction && (
            <AuctionDetailInfo auction={auction} />
          )}
          {activeTab === "VENDOR_FORM" && auction && auction && (
            <AuctionVendorForm auction={auction} statusUpdate={updateStatus} />
          )}
          {activeTab === "BID_HALL" && auction && (
            <div className=" p-12">
              {auction.t_c ? (
                <AuctionBidHall auction={auction} bidMap={bids} />
              ) : (
                <BidHallTermsAndCondition
                  handleSubmit={acceptTermCondition}
                  handleDecline={handleNavigateToLastTab}
                />
              )}
            </div>
          )}
          {activeTab === "COMMENT" && auction && (
            <div className="comment-section-main-container p-12 border-box">
              <div className="comment-section-inner-container px-12 py-24 border-box flex-column  justify-content-space-between">
                <div className="flex-column gap-12 flex-1 overflow-scroll">
                  {auction.comments.length > 0 &&
                    auction.comments.map((comment, index) => (
                      <CommentCard
                        key={comment.id}
                        avatar={comment.avatar}
                        name={comment.name}
                        timestamp={comment.created_at}
                        description={comment.comment}
                        commentFiles={comment.files}
                        user_id={comment.user_id}
                        hideType={true}
                      />
                    ))}
                  <div ref={messagesEndRef} />
                </div>
                <div className="add-comment-container full-width border-box p-8 flex-column">
                  {selectedFiles && (
                    <div className="flex-column">
                      {selectedFiles.map((file: File, index: number) => {
                        return (
                          <div
                            style={{ background: "white" }}
                            className="file-card flex-row justify-content-space-between border-box p-8"
                            key={index}
                          >
                            <div className="file-left flex-row gap-8 align-items-center">
                              <img
                                src={getFileIcon(file)}
                                alt="file-icon"
                                height={16}
                                width={16}
                                className="file-preview"
                              />
                              <p
                                className="xetgo-font-tag file-name bold"
                                style={{ color: "#E1E1E1" }}
                              >
                                |
                              </p>
                              <p
                                className="xetgo-font-tag file-name bolder"
                                style={{ color: "#4A4A4A" }}
                              >
                                {file.name}
                              </p>
                            </div>
                            <div className="flex-row gap-12 align-items-center">
                              <img
                                className="cursor-pointer"
                                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012084858-close.svg"
                                alt="close"
                                width={16}
                                height={16}
                                onClick={() => deleteFile(file)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="full-width border-box xetgo-font-tag comment-value">
                    <textarea
                      style={{ outline: "none" }}
                      className="full-width p-8 border-box xetgo-font-tag"
                      rows={3}
                      onChange={(e) => setNewComment(e.target.value)}
                      value={newComment}
                    ></textarea>
                  </div>

                  <div className="attach-send-comment-container flex-row align-items-center justify-content-space-between">
                    <input
                      onChange={handleFileChange}
                      id="commentFileInput"
                      name="file"
                      type="File"
                      style={{ display: "none" }}
                      multiple
                    />
                    <label htmlFor="commentFileInput">
                      <img
                        className="cursor-pointer"
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240117075035-_WYSIWYG-toolbar-button.svg"
                        alt="attachment-icon"
                      />
                    </label>
                    <div
                      onClick={handleCommentSubmit}
                      className="send-btn xetgo-font-tag px-13 py-5 flex-row align-items-center justify-content-center gap-12 cursor-pointer"
                    >
                      <span>Send</span>{" "}
                      <img
                        height={16}
                        width={16}
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241128100505-send.svg"
                        alt="send-icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuctionDetail;
