import { useSelector } from "react-redux";
import AddressDropdown from "../../components/AddressDropdown/AddressDropdown";
// import { negotiations } from "./NegotiationList.data";
import "./NegotiationList.scss";
import React, { useEffect, useMemo, useState } from "react";
import { getUserWarehouseAddresses, userDetails } from "../../redux/user/userSelector";
import { UserAddress } from "../../redux/user/userTypes";
import Filter from "../../components/Filter/Filter";
import { FilterOptionSchema } from "../../components/Filter/filter.data";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { debounce } from "lodash";
import { formatDate } from "../../utils/Helper";
import { formatTime } from "../../utils/date-util";

interface Negotiation{
  id:number,
  sku_name:string,
  vendor_name:string,
  address: {
    city: string,
    state: string,
    address_line: string
  },
  status:string,
}

interface NegotiationPrices {
  id:number,
  negotiation_id:number,
  created_at:string,
  category:string,
  price:number,
  user_id:number,
}

const NegotiationList: React.FC = () => {
  const user = useSelector(userDetails);
  const [searchedText, setSearchedText] = useState("");
  const [negotiations,setNegotiations] = useState<Negotiation[]>([])
  const [negotiationPrices,setNegotiationPrices] = useState<NegotiationPrices[]>([])

  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [negotiationDetailConterPrice, setNegotionDetailConterPrice] = useState<number[]>(
    [],
  );
  
  const warehouseAddresses = useSelector(getUserWarehouseAddresses);
 
  const allowedStatuses: FilterOptionSchema[] = [
    {
      id: 1,
      name: "In Progress",
      code:'IN_PROGRESS'
    },
    {
      id: 2,
      name: "Completed",
      code:'COMPLETED'
    },
  ];
  const [filterVendorList, setFilterVendorList] = useState<
    FilterOptionSchema[]
  >([]);
 

  useEffect(() => {
    // fetchVendors();
  }, []);

  useEffect(()=>{
    getNegotiations(searchedText)
  },[])

  

  const getNegotiations = async (searchText: string) => {
    try {
      const params = {
        vendor_ids: user.company_id, 
        searchText, 
      };
      const { data } = await ApiService().client.get(URLS.NEGOTIATIONS.GET_NEGOTIATIONS, {
        params,
      });
      setNegotiations(data.data)
  
    } catch (error) {
      console.error("Error fetching Negotiations", error);
    }
  };
  

  const handlePriceChange = (index: number, value: string) => {
    const updatedPrices = [...negotiationDetailConterPrice];
    updatedPrices[index] = parseFloat(value) || 0;
    setNegotionDetailConterPrice(updatedPrices);
  };

  const getNegotiationPrices = async(negotiation_id:number)=>{
    try {
      const { data } = await ApiService().client.get(
        URLS.NEGOTIATIONS.NEGOTIATION_PRICES,{
          params: {
            negotiation_id:negotiation_id,
          },
        }
      );
      setNegotiationPrices(data.data)
    } catch (error) {
      console.error("Error fetching Negotiations", error);
    }
  }



  const handleExpansion = (index: number,negotiation_id:number) => {
    if (expandedIndex == index) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
      getNegotiationPrices(negotiation_id)
    }
  };

  const handleCounter = async (index: number, negotiationId: number) => {
    try {
      if (!negotiationDetailConterPrice[index]) {
        return;
      }
  
      const { data } = await ApiService().client.post(URLS.NEGOTIATIONS.NEGOTIATION_PRICES, {
        negotiation_id: negotiationId,
        category: user.role,
        price: negotiationDetailConterPrice[index],
      });
      setNegotiationPrices([data.data, ...negotiationPrices]);
      const updatedPrices = [...negotiationDetailConterPrice];
      updatedPrices[index] =  0;
      setNegotionDetailConterPrice(updatedPrices);
    } catch (error) {
      console.error("Error handling counter", error);
    }
  };

  const handleApproveNegotiationPrice = async (
    negotiation_id: number,
    price: number,
    vendor_id: number
  ) => {
    try {
      const { data } = await ApiService().client.post(
        URLS.NEGOTIATIONS.APPROVE_NEGOTIATION,
        {
          negotiation_id,
          price,
          vendor_id,
        }
      );
  
      setNegotiations((prevNegotiations) =>
        prevNegotiations.map((negotiation) =>
          negotiation.id === negotiation_id
            ? { ...negotiation, status: 'COMPLETED' }
            : negotiation
        )
      );
    } catch (e) {
      console.error('Error approving negotiation price:', e);
    }
  };
  return (
    <div className="negotion-list-main-page">
      <div className="upper-bar">
        <p className="xetgo-font-tag bold px-16 py-12">Negotiations</p>
      </div>
 
      <div className="negotiation-lower-main-container overflow-scroll p-8">
       
        <div className="negotiation-main-container border-box p-8">
          <div className="header-grid-container negotiation-grid xetgo-font-tag bold">
            <p className="px-8 py-12">S. No.</p>
            <p className="px-8 py-12">SKU</p>
            <p className="px-8 py-12">Vendor Name</p>
            <p className="px-8 py-12"> Address</p>
            <p className="px-8 py-12"> Status</p>
          </div>
          {negotiations.length > 0 && (
            <div className="negotiation-all-sku-details-main-container">
              {negotiations.map((negotiation, index) => (
                <div key={negotiation.id} className="sku-detail-negotiations-wrapper">
                  <div
                    onClick={() => handleExpansion(index,negotiation.id)}
                    className={`negotiation-sku negotiation-grid xetgo-font-tag cursor-pointer ${
                      index % 2 != 0 && "negotiation-even-bg"
                    }`}
                  >
                    <p className="flex-row align-items-center  px-8 py-12">
                      {index + 1}
                    </p>
                    <p className="flex-row align-items-center  px-8 py-12">
                      {negotiation.sku_name}
                    </p>
                    <p className="flex-row align-items-center  px-8 py-12">
                      {negotiation.vendor_name}
                    </p>
                    <p className="flex-row gap-10 align-items-center px-8 py-12 ">
                      <span className="flex-1" style={{fontWeight:'700',minWidth:'150px'}}>{negotiation.address.city}, {negotiation.address.state}</span> <span>{negotiation.address.address_line}</span>
                    </p>
                    <div className="flex-row align-items-center px-8 py-12">
                      <span
                        className={`status-container flex-row align-items-center justify-content-center px-12 py-5 ${
                          negotiation.status == "COMPLETED"
                            ? "status-completed"
                            : "in-progress"
                        }`}
                      >
                        {negotiation.status==='COMPLETED'?'Completed':'In Progress'}
                      </span>
                    </div>
                  </div>

                  {expandedIndex === index && (
                    <div className="expanded-negotiation-main-container">
                      <div className="header-negotiation-expanded expanded-negotiation-grid xetgo-font-tag bold">
                        <p className="px-8 py-12"></p>
                        <p className="header-border-btm-expanded flex-row align-items-center px-8 py-12">
                          Date
                        </p>
                        <p className="header-border-btm-expanded flex-row align-items-center px-8 py-12">
                          Category
                        </p>
                        <p className="header-border-btm-expanded flex-row align-items-center px-8 py-12">
                          Price
                        </p>
                        <p className="header-border-btm-expanded flex-row align-items-center px-8 py-12">
                          Actions
                        </p>
                      </div>
                      {negotiationPrices.length > 0 && (
                        <div className="expanded-all-negotiation-container">
                          {negotiationPrices.map(
                            (negotiationPrice, negotiationIndex) => (
                              <div
                                key={negotiationIndex}
                                className="expanded-negotiation-grid "
                              >
                                <p></p>
                                <p className="header-border-btm-expanded negotiation-border-right flex-row gap-2 align-items-center px-12 py-18 xetgo-font-tag">
                                  {formatDate(negotiationPrice.created_at)}, 
                                  <span style={{ color: "#949494" }}>
                                    {formatTime(negotiationPrice.created_at)}
                                  </span>
                                </p>
                                <p
                                  style={{ color: "#949494" }}
                                  className="header-border-btm-expanded negotiation-border-right flex-row align-items-center px-12 py-18 xetgo-font-tag"
                                >
                                  {negotiationPrice.category}
                                </p>
                                <div className="header-border-btm-expanded negotiation-border-right flex-row align-items-center justify-content-space-between px-12 py-18 xetgo-font-tag">
                                  <p>₹{negotiationPrice.price}</p>
                                  {negotiationIndex === 0 && negotiationPrice.category === 'client'&& negotiation.status !== 'COMPLETED'&& (
                                    <input
                                      className="negotion-price-input px-12 py-6 xetgo-font-tag"
                                      type="number"
                                      onChange={(e) =>
                                        handlePriceChange(index, e.target.value)
                                      }
                                      value={negotiationDetailConterPrice[index] || ""}
                                      placeholder="Enter Price"
                                    />
                                  )}
                                </div>
                                {negotiationIndex === 0 && negotiationPrice.category === 'client' && negotiation.status !=='COMPLETED'? (
                                  <div className="actions-btn-container header-border-btm-expanded flex-row align-items-center gap-8 k">
                                    <div onClick={()=>handleApproveNegotiationPrice(negotiation.id,negotiationPrice.price,negotiationPrice.user_id)} className="approve-btn flex-row align-items-center gap-8 px-12 py-8 xetgo-font-tag cursor-pointer">
                                      <img
                                        height={16}
                                        width={16}
                                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241225225637-Static+Icon.svg"
                                        alt="approve-icon"
                                      />
                                      <p>Approve</p>
                                    </div>
                                    <div onClick={()=>handleCounter(index,negotiation.id)} className="approve-btn counter-btn flex-row align-items-center gap-8 px-12 py-8 xetgo-font-tag cursor-pointer">
                                      <img
                                        height={16}
                                        width={16}
                                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241225230615-indian-rupee.svg"
                                        alt="rupee-icon"
                                      />
                                      <p>Counter</p>
                                    </div>
                                  </div>
                                ) : negotiationIndex === 0 && negotiationPrice.category ==='vendor' && negotiation.status!=='COMPLETED'?(
                                  <div className="flex-row align-items-center px-12 py-8"><p className="review-btn xetgo-font-tag py-6 px-12 flex-row align-items-center justify-content-center ">
                                    In Review</p></div>
                                ): negotiationIndex===0 && negotiation.status=='COMPLETED'? <div className="flex-row align-items-center px-12 py-8"><p className="review-btn approved-btn xetgo-font-tag py-6 px-12 flex-row align-items-center justify-content-center ">
                                    Approved</p></div>:<div className="px-12 py-8"></div>}
                              </div>
                            ),
                          )}
                        </div>
                      )}

                      {negotiationPrices.length === 0 && (
                        <div className="expanded-all-negotiation-container expanded-negotiation-grid ">
                          <p></p>
                          <p
                            style={{ color: "#CACACA" }}
                            className="header-border-btm-expanded negotiation-border-right flex-row align-items-center px-12 py-18 xetgo-font-tag"
                          >
                            Awaiting
                          </p>
                          <p
                            style={{ color: "#CACACA" }}
                            className="header-border-btm-expanded negotiation-border-right flex-row align-items-center px-12 py-18 xetgo-font-tag"
                          >
                            Awaiting
                          </p>
                          <div className="header-border-btm-expanded negotiation-border-right flex-row align-items-center justify-content-space-between px-12 py-18 xetgo-font-tag">
                            <p style={{ color: "#CACACA" }}>₹</p>

                            <input
                              style={{
                                background: "#F6F6F6",
                                color: "#DBDBDB",
                                cursor: "not-allowed",
                              }}
                              className="negotion-price-input px-12 py-6 xetgo-font-tag"
                              type="number"
                              onChange={(e) =>
                                handlePriceChange(index, e.target.value)
                              }
                              value={negotiationDetailConterPrice[index] || ""}
                              placeholder="Enter Price"
                              disabled
                            />
                          </div>
                          <div className="actions-btn-container header-border-btm-expanded flex-row align-items-center gap-8 ">
                            <div className="approve-btn approve-btn-disabled flex-row align-items-center gap-8 px-12 py-8 xetgo-font-tag">
                              <img
                                height={16}
                                width={16}
                                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241226073810-Static+Icon.svg"
                                alt="approve-icon"
                              />
                              <p>Approve</p>
                            </div>
                            <div  className="approve-btn counter-btn counter-btn-disabled flex-row align-items-center gap-8 px-12 py-8 xetgo-font-tag">
                              <img
                                height={16}
                                width={16}
                                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241226074016-indian-rupee.svg"
                                alt="rupee-icon"
                              />
                              <p>Counter</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {negotiations.length === 0 && (
            <div className="empty-state-negotiation-main-container flex-column align-items-center justify-content-center">
              <img
                height={140}
                width={140}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241226063302-Group+1171276144.svg"
                alt="negotiate-icon"
              />
              <div className="flex-column align-items-center justify-content-center gap-8">
                <p className="empty-heading xetgo-font-body bolder">
                  No Negotiations Yet
                </p>
                <p style={{ color: "#A0A0A0" }} className="xetgo-font-tag">
                  It looks like there are no active negotiations at the moment.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NegotiationList;
