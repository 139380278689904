import { useEffect, useRef, useState } from "react";
import { ToastSchema } from "../../models/Toast";
import { useToast } from "../../hooks/useToast";
import "./Toast.scss";

const toastTypes = {
  success: {
    icon: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241129064026-check-circle-2.svg",
    iconClass: "success-icon",
    progressBarClass: "success",
  },
  info: {
    icon: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241129064026-info.svg",
    iconClass: "info-icon",
    progressBarClass: "info",
  },
};

const Toast: React.FC<ToastSchema> = ({ title, message, type, id }) => {
  const [dismissed, setDismissed] = useState(false);
  const { icon, iconClass } = toastTypes[type];
  const timerID = useRef<NodeJS.Timeout | null>(null);
  const toast = useToast();

  const handleDismiss = () => {
    setDismissed(true);
    setTimeout(() => {
      toast.remove(id);
    }, 1000);
  };

  useEffect(() => {
    timerID.current = setTimeout(() => {
      handleDismiss();
    }, 5000);

    return () => {
      if (timerID.current) {
        clearTimeout(timerID.current);
      }
    };
  }, []);

  return (
    <div
      className={`toast ${
        dismissed ? "toast-dismissed" : ""
      } ${iconClass}-container`}
    >
      <div className="flex-row gap-8 p-16">
        <div className={iconClass}>
          <img src={icon} height={16} width={16} />
        </div>
        <div className="flex-column gap-4">
          <p className="toast-title">{title}</p>
          <p className="toast-message">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default Toast;
