import React, { useState } from "react";
import "./BidConfirmationPopup.scss";
import { commaFormat } from "../../utils/Helper";
import { toWords } from "number-to-words";

interface IBidConfirmationProps {
  unitRate: number;
  totalRate: number;
  handleClose: () => void;
  saveBid: () => void;
}

const BidConfirmationPopup: React.FC<IBidConfirmationProps> = ({
  unitRate,
  totalRate,
  handleClose,
  saveBid,
}) => {
  const [stepOne, setStepOne] = useState(true);

  const formatWords = (number: number): string => {
    const words = toWords(number);
    return words.charAt(0).toUpperCase() + words.slice(1) + " only";
  };

  const formatNumberToWords = (number: number): string => {
    const [integerPart, decimalPart] = number.toString().split(".");
    const integerWords = toWords(parseInt(integerPart));
    const decimalWords = decimalPart
      ? decimalPart
          .split("")
          .map((digit) => toWords(parseInt(digit)))
          .join(" ")
      : "";

    const formattedWords = decimalPart
      ? `${integerWords} point ${decimalWords}`
      : integerWords;

    return (
      formattedWords.charAt(0).toUpperCase() + formattedWords.slice(1) + " only"
    );
  };

  const handleConfirmation = () => {
    if (stepOne) {
      setStepOne(false);
    } else {
      saveBid();
      handleClose();
    }
  };

  return (
    <div className="bid-hall-confirmation-popup-main-container flex-column gap-18 align-items-center p-12">
      <div className="full-width">
        <div className="flex-row align-items-center justify-content-flex-end full-width">
          <img
            className="cursor-pointer"
            onClick={handleClose}
            height={16}
            width={16}
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012084858-close.svg"
            alt="close-icon"
          />
        </div>
        <div className="flex-column align-items-center full-width gap-12">
          <div className="alert-icon-container flex-column align-items-center justify-content-center full-width">
            <img
              height={28}
              width={48}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241030053829-Group+1171276135.svg"
              alt="alert"
            />
          </div>
          <p style={{ color: "#454545" }} className="xetgo-font-tag">
            Please {stepOne ? "Confirm" : "Re-Confirm"} the bid amount to place
            your bid.
          </p>
          <div
            style={{ color: "#ABABAB" }}
            className="flex-column align-items-center justigy-content-center gap-2 xetgo-font-tag full-width"
          >
            <p>
              Unit Amt: ₹ {commaFormat(unitRate.toString())} (
              {formatNumberToWords(unitRate)})
            </p>
            <p>
              Total Amt: ₹ {commaFormat(totalRate.toString())} (
              {formatNumberToWords(totalRate)})
            </p>
          </div>
        </div>
      </div>
      <div className="confirmation-btns-container flex-row align-items-center justify-content-center gap-14 xetgo-font-tag">
        {stepOne ? (
          <p
            onClick={handleClose}
            className="edit-btn flex-row align-items-center justify-content-center border-box xetgo-font-tag py-8 px-20 cursor-pointer "
          >
            Edit
          </p>
        ) : (
          <p
            onClick={handleClose}
            className="edit-btn flex-row align-items-center justify-content-center border-box xetgo-font-tag py-8 px-20 cursor-pointer "
          >
            Cancel
          </p>
        )}

        <p
          onClick={handleConfirmation}
          className="edit-btn confirm-btn flex-row align-items-center justify-content-center border-box xetgo-font-tag cursor-pointer py-8 px-20 "
        >
          Confirm
        </p>
      </div>
    </div>
  );
};

export default BidConfirmationPopup;
