import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "TypoPRO Montserrat",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Medium.ttf",
      fontWeight: 500,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-SemiBold.ttf",
      fontWeight: 600,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Bold.ttf",
      fontWeight: 700,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-MediumItalic.ttf",
      fontStyle: "italic",
      fontWeight: 500,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-BoldItalic.ttf",
      fontStyle: "italic",
      fontWeight: 700,
    },
  ],
});
// Define styles
export const styles = StyleSheet.create({
  page: {
    fontFamily: "TypoPRO Montserrat",
    padding: "30px",
    zIndex: 20,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
  },
  borderOut: {
    position: "absolute",
    padding: "20px",
    height: "100vh",
    width: "100vw",
    top: "0",
    left: "0",
  },
  borderIn: {
    border: "1px solid #8E8E8E",
    height: "100%",
    width: "100%",
  },
  pageHeader: {
    padding: 8,
    backgroundColor: "#FFF1E3",
    color: "#000059",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  pageHeaderLeftField: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    alignItems: "flex-start",
  },
  pageHeaderRightField: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    alignItems: "flex-end",
  },
  pageHeaderKey: {
    color: "#000059",
    fontWeight: 400,
    fontSize: "8px",
  },
  pageHeaderValue: {
    color: "#000059",
    fontSize: "8px",
    fontStyle: "italic",
    fontWeight: 600,
  },
  pageHeaderTitle: {
    color: "#000059",
    fontWeight: "bold",
    fontSize: "12px",
  },
  clientInfoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "24px",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  auctionType: {
    padding: "4px 4px 4px 8px",
    backgroundColor: "#000059",
    color: "#EEF6F5",
    fontWeight: 500,
    borderTopRightRadius: "6px",
    borderBottomRightRadius: "6px",
    fontSize: "8px",
    width: "84px",
  },
  auctionTitle: {
    color: "#000059",
    fontWeight: 700,
    fontSize: "9px",
  },
  auctionTime: {
    color: "#000000",
    fontSize: "9px",
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "flex-end",
  },
  clientLogo: {
    width: "40px",
    borderRadius: "50%",
  },
  auctionDescription: {
    color: "#000059",
    fontSize: "8px",
    marginTop: "24px",
  },
  auctionBidsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  auctionBidAddressContainer: {
    border: "1px solid #E5E7EB",
    borderRadius: "6px",
    marginTop: "28px",
    overflow: "hidden",
  },
  addressDetailContainer: {
    backgroundColor: "#FEFEF1",
    borderBottom: "1px solid #E8E8E8",
    display: "flex",
    flexDirection: "row",
    padding: "4px 12px",
    gap: "8px",
    alignItems: "center",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
  },
  addressState: {
    color: "#4A4A4A",
    fontSize: "8px",
    fontWeight: 600,
  },
  addressLine: {
    color: "#545454",
    fontSize: "8px",
    fontWeight: 500,
  },
  skuNameContainer: {
    backgroundColor: "#E5F1FF",
    padding: "4px 6px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid #E5E7EB",
  },
  skuLeft: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    color: "#000059",
    fontSize: "8px",
  },
  skuRight: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
  },
  skuFieldContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  },
  skuFieldKey: {
    color: "#6B7280",
    fontSize: "8px",
  },
  skuFieldValue: {
    color: "#000059",
    fontSize: "8px",
  },
  bidsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  bidsHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F1EFFF",
    borderBottom: "1px solid #E5E7EB",
    fontSize: "8px",
    fontWeight: 700,
  },
  unitRateKey: {
    padding: "4px 12px",
    color: "#545454",
    borderRight: "1px solid #E5E7EB",
    flex: 1,
  },
  bidTimeKey: {
    padding: "4px 12px",
    color: "#545454",
    borderRight: "1px solid #E5E7EB",
    width: "120px",
  },
  totalRateKey: {
    padding: "4px 12px",
    color: "#545454",
    borderRight: "1px solid #E5E7EB",
    flex: 1.6,
  },
  remarkKey: {
    padding: "4px 12px",
    color: "#545454",
    flex: 2.5,
  },
  rankKey: {
    padding: "4px 12px",
    color: "#545454",
    width: "44px",
    borderRight: "1px solid #E5E7EB",
  },
  bidsRowContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ffffff",
    borderBottom: "1px solid #E5E7EB",
    fontSize: "8px",
    fontWeight: 400,
  },
  unitRateValue: {
    padding: "4px 12px",
    color: "#4A4A4A",
    borderRight: "1px solid #E5E7EB",
    flex: 1,
  },
  totalRateValue: {
    padding: "4px 12px",
    color: "#4A4A4A",
    borderRight: "1px solid #E5E7EB",
    flex: 1.6,
  },
  bidTimeValue: {
    padding: "4px 12px",
    color: "#4A4A4A",
    borderRight: "1px solid #E5E7EB",
    width: "120px",
  },
  remarkValue: {
    padding: "4px 12px",
    color: "#8E8E8E",
    flex: 2.5,
  },
  rankValue: {
    padding: "4px 12px",
    color: "#8E8E8E",
    width: "44px",
    borderRight: "1px solid #E5E7EB",
  },
  pageFooter: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    marginTop: 24,
    fontSize: "8",
    color: "#393A3A",
  },
  pageFooterName: {
    fontWeight: 400,
    color: "#8F8F8F",
    fontSize: "8px",
  },
  footerLine: {
    backgroundColor: "#000059",
    height: "1px",
    width: "100%",
  },
});
