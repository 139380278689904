import React, { useEffect, useState } from "react";
import "./AuctionVendorForm.scss";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import { Auction } from "../../models/Auction";
import {
  OptionsSchema,
  QuestionDetail,
  VendorFormResponse,
} from "../../models/ClientRequestForm";
import CustomQuestionForm from "../../components/CustomQuestionForm/CustomQuestionForm";
import { useParams } from "react-router-dom";

const AuctionVendorForm: React.FC<{
  auction: Auction;
  statusUpdate: (status: string) => void;
}> = ({ auction, statusUpdate }) => {
  const [formResponse, setFormResponse] = useState<VendorFormResponse>();
  const [fileMap, setFileMap] = useState<{ [key: number]: File[] }>({});
  const [formData, setFormData] = useState<FormData>(new FormData());

  useEffect(() => {
    initialiseFormResponse(auction.form.id, auction.form.questions);
  }, [auction.form]);

  useEffect(() => {
    const newFormData = new FormData();

    Object.keys(fileMap).forEach((questionId) => {
      const files = fileMap[parseInt(questionId)];
      files.forEach((file) => {
        newFormData.append("questions[]", questionId);
        newFormData.append("files[]", file);
        newFormData.append("fileNames[]", file.name);
      });
    });

    setFormData(newFormData);
  }, [fileMap]);

  const initialiseFormResponse = (
    vendorFormId: number,
    questions: QuestionDetail[]
  ) => {
    const initialResponses = questions.map((question) => ({
      question_id: question.id,
      input_type: question.input_type,
      responses: [],
    }));

    const initialFormResponse: VendorFormResponse = {
      vendor_form_id: vendorFormId,
      responses: initialResponses,
    };

    setFormResponse(initialFormResponse);
  };

  const onQuestionResponseCapture = (
    question: QuestionDetail,
    response: string[] | File[] | OptionsSchema[]
  ) => {
    if (response[0] instanceof File) {
      setFileMap((prevFileMap) => ({
        ...prevFileMap,
        [question.id]: response as File[],
      }));
    } else {
      setFormResponse((prevFormResponse) => {
        if (!prevFormResponse) return undefined;

        const updatedResponses = prevFormResponse.responses.map((res) => {
          if (res.question_id === question.id) {
            return {
              ...res,
              responses: response,
            };
          }
          return res;
        });

        return {
          ...prevFormResponse,
          responses: updatedResponses,
        };
      });
    }
  };

  const handleSaveDetails = async () => {
    if (!formResponse) {
      return;
    }
    formData.append("formResponse", JSON.stringify(formResponse));
    try {
      await ApiService().client.post(
        URL.USERS.FETCH_CLIENT_REQUEST_FORM(
          formResponse?.vendor_form_id as number
        ),
        formData
      );
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  const submitForm = () => {
    handleSaveDetails();
    statusUpdate("PENDING_APPROVAL");
  };

  return (
    <>
      {auction && (
        <div className="flex-column gap-16 full-height overflow-hidden">
          <div className="auction-detail-vendor-form-page full-width flex-column gap-16 p-12 flex-1 overflow-scroll">
            <p className="xetgo-font-button bold" style={{ color: "#484848" }}>
              {auction.form.title}
            </p>
            {auction.form.description && (
              <p className="xetgo-font-tag">{auction.form.description}</p>
            )}
            <div className="flex-column gap-12">
              {auction.form.questions.map((question, index) => (
                <CustomQuestionForm
                  key={index}
                  questionDetails={question}
                  onValueChange={onQuestionResponseCapture}
                  disabled={
                    auction.status === "PENDING_APPROVAL" ||
                    auction.status === "APPROVED" ||
                    auction.status === "DECLINED" ||
                    auction.status === "REJECTED"
                  }
                />
              ))}
            </div>
          </div>
          {(auction.status === "ACCEPTED" ||
            auction.status === "EDIT_REQUESTED") && (
            <div className="auction-btn-container flex-row gap-12 justify-content-flex-end">
              <p
                className="px-24 py-8 draft-btn xetgo-font-tag bold cursor-pointer"
                onClick={handleSaveDetails}
              >
                Save as Draft
              </p>
              <p
                className="px-24 py-8 submit-btn xetgo-font-tag bold cursor-pointer"
                onClick={submitForm}
              >
                Submit
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AuctionVendorForm;
