import React, { useMemo } from "react";
import "./VendorBidHistoryReport.scss";
import { BlobProvider } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import BidHistoryReport from "../../reports/AuctionReport/BidHistoryReport";
import { Auction, BidMapResponse, IAuctionBid } from "../../models/Auction";

interface VendorBidHistoryReportProps {
  auction: Auction;
  bids: BidMapResponse;
}

const VendorBidHistoryReport: React.FC<VendorBidHistoryReportProps> = ({
  auction,
  bids,
}) => {
  const currentUser = useSelector(userDetails);

  const memoizedDocument = useMemo(() => {
    const today = dayjs().format("DD-MM-YY");
    return (
      <BidHistoryReport
        user={currentUser}
        currentDate={today}
        auction={auction}
        bids={bids}
      />
    );
  }, [auction, bids]);

  const handleOpenQuotationPdf = (url: string, filename: string) => {
    window.open(url, "_blank");
  };

  return (
    <BlobProvider document={memoizedDocument}>
      {({ blob, url, loading, error }) => {
        if (loading) return <CircularProgress size={20} />;
        if (error) {
          console.log("ERROR PDF", error);
          return (
            <button className="report-download-button" disabled>
              Error generating PDF
            </button>
          );
        }
        const filename = `BidHistoryReport.pdf`;
        return (
          <div
            style={{ color: "#5151EC" }}
            className="flex-row align-items-center justify-content-center gap-8 cursor-pointer"
            onClick={() => handleOpenQuotationPdf(url || "", filename)}
          >
            <img
              height={16}
              width={16}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240904094334-download_purple.svg"
              alt="download-icon"
            />
            <p>Download Bid History</p>
          </div>
        );
      }}
    </BlobProvider>
  );
};

export default VendorBidHistoryReport;
