import { useNavigate } from "react-router-dom";
import LiveAuction from "../../components/LiveAuction/LiveAuction";
import UpcomingAuction from "../../components/UpcomingAuction/UpcomingAuction";
import "./Auction.scss";
import React, { useEffect, useState } from "react";
import PastAuction from "../../components/PastAuction/PastAuction";
import { IAuctionItem } from "../../models/Auction";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";

const Auction: React.FC = () => {
  const navigate = useNavigate();
  const [pastAuctions, setPastAuctions] = useState<IAuctionItem[]>([]);
  const [liveAuctions, setLiveAuctions] = useState<IAuctionItem[]>([]);
  const [upcomingAuctions, setUpcomingAuctions] = useState<IAuctionItem[]>([]);

  useEffect(() => {
    fetchAuctions();
  }, []);

  const fetchAuctions = async () => {
    try {
      const { data } = await ApiService().client.get(URL.AUCTION.LIST);
      setPastAuctions(data.past);
      setLiveAuctions(data.live);
      setUpcomingAuctions(data.upcoming);
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  return (
    <div className="auction-main-page full-width flex-column">
      <div className="upper-bar">
        <p className="xetgo-font-tag bold px-16 py-12">Auctions</p>
      </div>
      <div className="auction-header-bar flex-row align-items-center justify-content-end py-10 "></div>
      <div className="all-auction-type-main-container flex-1 flex-row gap-26 p-16">
        <PastAuction data={pastAuctions} />
        <LiveAuction data={liveAuctions} />
        <UpcomingAuction data={upcomingAuctions} />
      </div>
    </div>
  );
};

export default Auction;
