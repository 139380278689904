import React from "react";
import { Page, Text, View, Document, Link, Image } from "@react-pdf/renderer";
import { UserState } from "../../redux/user/userReducer";
import {
  Auction,
  BidMapAddress,
  BidMapResponse,
  IAuctionBid,
} from "../../models/Auction";
import { commaFormat, formatDateTime } from "../../utils/Helper";
import { styles } from "./VendorAuctionReportStyles";

interface VendorAuctionReportProps {
  user: UserState;
  currentDate: string;
  auction: Auction;
  bids: BidMapResponse;
}

const VendorAuctionReport: React.FC<VendorAuctionReportProps> = ({
  user,
  currentDate,
  auction,
  bids,
}) => {
  const fileName = (
    auction.title +
    "___" +
    user.company_details?.name +
    "_ Bid_Result.pdf"
  ).replaceAll(" ", "_");

  return (
    <Document title={fileName}>
      <Page size="A4" style={styles.page}>
        <View fixed style={styles.borderOut}>
          <View style={styles.borderIn}></View>
        </View>
        <View>
          <View style={styles.pageHeader}>
            <View style={styles.pageHeaderLeftField}>
              <Text style={styles.pageHeaderKey}>Auction ID</Text>
              <Text style={styles.pageHeaderValue}>#{auction.auction_id}</Text>
            </View>
            <Text style={styles.pageHeaderTitle}>
              Vendor Auction Bid Result
            </Text>
            <View style={styles.pageHeaderRightField}>
              <Text style={styles.pageHeaderKey}>Date</Text>
              <Text style={styles.pageHeaderValue}>{currentDate}</Text>
            </View>
          </View>
          <View style={styles.clientInfoContainer}>
            <View style={styles.leftContainer}>
              <Text style={styles.auctionType}>Reverse Auction</Text>
              <Text style={styles.auctionTitle}>{auction.title}</Text>
              <Text style={styles.auctionTime}>
                {formatDateTime(auction.start_time).replace(/-/g, "/")} -{" "}
                {formatDateTime(auction.end_time).replace(/-/g, "/")}
              </Text>
            </View>
            <View style={styles.rightContainer}>
              {/* <Image
                style={styles.clientLogo}
                src={auction.client?.avatar}
              ></Image> */}
              <Text style={styles.auctionTitle}>{auction.client.name}</Text>
            </View>
          </View>
          <Text style={styles.auctionDescription}>
            {auction.description.replaceAll(/<[^>]*>/g, " ")}
          </Text>
          <View style={styles.auctionBidsContainer}>
            {Object.values(bids).map((address: BidMapAddress, index) => {
              return (
                <View
                  style={styles.auctionBidAddressContainer}
                  key={`address-${index}`}
                >
                  <View style={styles.addressDetailContainer}>
                    <Text style={styles.addressState}>{address.state}</Text>
                    <Text style={styles.addressLine}>{address.name}</Text>
                  </View>
                  <View style={styles.bidsHeaderContainer}>
                    <Text style={styles.slKey}>Sl.No.</Text>
                    <Text style={styles.skuNameKey}>Sku Name</Text>
                    <Text style={styles.unitRateKey}>Quantity</Text>
                    <Text style={styles.unitRateKey}>Start Price</Text>
                    <Text style={styles.unitRateKey}>Unit Rate</Text>
                    <Text style={styles.totalRateKey}>Total Rate</Text>
                    <Text style={styles.rankKey}>Rank</Text>{" "}
                  </View>
                  {address.skus.map((sku, sindex) => {
                    return (
                      <View key={`skus-${sku.id}`}>
                        <View style={styles.bidsContainer}>
                          <View
                            style={{
                              ...styles.bidsRowContainer,
                              borderBottom:
                                index + 1 === Object.keys(bids).length &&
                                sindex + 1 === address.skus.length
                                  ? "none"
                                  : "1px solid #E5E7EB",
                              borderBottomLeftRadius:
                                index + 1 === Object.keys(bids).length &&
                                sindex + 1 === address.skus.length
                                  ? "6px"
                                  : "0px",
                              borderBottomRightRadius:
                                index + 1 === Object.keys(bids).length &&
                                sindex + 1 === address.skus.length
                                  ? "6px"
                                  : "0px",
                            }}
                            key={`bids-${sindex}`}
                          >
                            <Text style={styles.slValue}>
                              {sindex + 1}
                            </Text>
                            <Text style={styles.skuNameValue}>{sku.name}</Text>
                            <Text style={styles.unitRateValue}>
                              {commaFormat(sku.quantity.toString())} {sku.unit}
                            </Text>
                            <Text style={styles.unitRateValue}>
                              INR {commaFormat(sku.start_price.toString())}
                            </Text>
                            <Text style={styles.unitRateValue}>
                               {sku.bids.length > 0 ? `INR ${commaFormat(sku.bids[0].price.toString())}` : '-'}
                            </Text>
                            <Text style={styles.totalRateValue}>
                            {sku.bids.length > 0 ? `INR ${commaFormat(
                                (
                                  Math.round(
                                    sku.quantity * sku.bids[0].price * 100
                                  ) / 100
                                ).toString()
                              )}`: '-'}
                            </Text>
                            <Text style={styles.rankValue}>
                            {sku.bids.length > 0 ? `${sku.bids[0].bid_rank}` : '-'}
                            </Text>
                          </View>
                        </View>
                      </View>
                    );
                  })}
                </View>
              );
            })}
          </View>
        </View>
        <View style={styles.pageFooter}>
          <View style={styles.footerLine}></View>
          <Text style={styles.pageFooterName}>
            Confidentiality Note: This document is confidential and intended for
            the recipient&lsquo;s use only. Unauthorized sharing or distribution
            is prohibited.
          </Text>
          <Text style={styles.pageFooterName}>
            Disclaimer: This report is generated from auction data as recorded
            in the system. While every effort is made to ensure accuracy, Xetgo
            Private Limited is not liable for errors arising from technical
            issues, vendor inputs, or delays. All data is subject to
            verification.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default VendorAuctionReport;
