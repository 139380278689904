import { IAuctionItem } from "../../models/Auction";
import AuctionListCard from "../AuctionListCard/AuctionListCard";
import "./UpcomingAuction.scss";
import React from "react";

const UpcomingAuction: React.FC<{ data: IAuctionItem[] }> = ({ data }) => {
  return (
    <div className="upcoming-auction-main-container full-width flex-column p-20 border-box">
      <div className="auction-heading-container flex-column gap-18 px-3">
        <div className="flex-row gap-12 align-items-center">
          <div className="xetgo-font-caption bold flex-row gap-8 align-items-center">
            <span className="bullet-point-upcoming"></span>
            <p>Upcoming Auction</p>
          </div>
          {data.length > 0 && (
            <div className="upcoming-circular-count-container xetgo-font-tag flex-row align-items-center justify-content-center">
              {data.length}
            </div>
          )}
        </div>

        <p className="upcoming-lower-heading-bar"></p>
      </div>
      {data.length > 0 && (
        <div className="all-auction-main-container flex-1 flex-column gap-10">
          {data.map((auction: IAuctionItem) => (
            <AuctionListCard key={auction.id} type="PAST" detail={auction} />
          ))}
        </div>
      )}
      {data.length < 0 && (
        <div
          style={{ color: "#B2B2B2" }}
          className="empty-state flex-row align-items-center justify-content-center flex-1 xetgo-font-button bold"
        >
          No auction to show
        </div>
      )}
    </div>
  );
};

export default UpcomingAuction;
