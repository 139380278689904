import React, { useEffect, useState } from "react";
import "./ClientRequestFormDetail.scss";
import { useNavigate, useParams } from "react-router-dom";
import CustomQuestionForm from "../../components/CustomQuestionForm/CustomQuestionForm";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import {
  ClientFormDataSchema,
  OptionsSchema,
  QuestionDetail,
  VendorFormResponse,
} from "../../models/ClientRequestForm";

const ClientRequestFormDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [clientFormData, setClientFormData] = useState<ClientFormDataSchema>();
  const [formResponse, setFormResponse] = useState<VendorFormResponse>();
  const [fileMap, setFileMap] = useState<{ [key: number]: File[] }>({});
  const [formData, setFormData] = useState<FormData>(new FormData());

  useEffect(() => {
    if (id) {
      fetchClientFormData(Number(id)).then((r) => r);
    }
  }, [id]);

  useEffect(() => {
    const newFormData = new FormData();

    Object.keys(fileMap).forEach((questionId) => {
      const files = fileMap[parseInt(questionId)];
      files.forEach((file) => {
        newFormData.append("questions[]", questionId);
        newFormData.append("files[]", file);
        newFormData.append("fileNames[]", file.name);
      });
    });

    setFormData(newFormData);
  }, [fileMap]);

  const fetchClientFormData = async (id: number) => {
    try {
      const { data } = await ApiService().client.get(
        URL.USERS.FETCH_CLIENT_REQUEST_FORM(id)
      );
      setClientFormData(data.data);
      initialiseFormResponse(data.data.id, data.data.questions);
    } catch (error) {
      console.log("Error fetching form data", error);
    }
  };

  const initialiseFormResponse = (
    vendorFormId: number,
    questions: QuestionDetail[]
  ) => {
    const initialResponses = questions.map((question) => ({
      question_id: question.id,
      input_type: question.input_type,
      responses: [],
    }));

    const initialFormResponse: VendorFormResponse = {
      vendor_form_id: vendorFormId,
      responses: initialResponses,
    };

    setFormResponse(initialFormResponse);
  };

  const onQuestionResponseCapture = (
    question: QuestionDetail,
    response: string[] | File[] | OptionsSchema[]
  ) => {
    if (response[0] instanceof File) {
      setFileMap((prevFileMap) => ({
        ...prevFileMap,
        [question.id]: response as File[],
      }));
    } else {
      setFormResponse((prevFormResponse) => {
        if (!prevFormResponse) return undefined;

        const updatedResponses = prevFormResponse.responses.map((res) => {
          if (res.question_id === question.id) {
            return {
              ...res,
              responses: response,
            };
          }
          return res;
        });

        return {
          ...prevFormResponse,
          responses: updatedResponses,
        };
      });
    }
  };

  const handleSaveDetails = async () => {
    if (!formResponse) {
      return;
    }
    formData.append("formResponse", JSON.stringify(formResponse));
    console.log("FORM RES: ", formResponse);
    try {
      await ApiService().client.post(
        URL.USERS.FETCH_CLIENT_REQUEST_FORM(
          formResponse?.vendor_form_id as number
        ),
        formData
      );
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  const handleStatusUpdate = async (status: string) => {
    try {
      await ApiService().client.put(
        URL.USERS.STATUS_CLIENT_REQUEST_FORM_UPDATE(
          formResponse?.vendor_form_id as number
        ),
        {
          status,
        }
      );
      setClientFormData((val) => {
        if (val) {
          return { ...val, status };
        }
        return val;
      });
    } catch (error) {
      console.error("Error submitting form for client review", error);
    }
  };

  return (
    <div className="client-request-main-page full-width flex-column">
      <div className="upper-bar px-32 py-12 xetgo-font-tag flex-row gap-4">
        <span
          onClick={() => navigate("/profile")}
          className="sub-title bold cursor-pointer"
        >
          Profile
        </span>
        <span className="sub-title bold">/</span>
        <span
          onClick={() => navigate("/profile?tab=client_requests")}
          className="cursor-pointer sub-title bold"
        >
          Client Requests
        </span>
        <span>/</span>
        <span className="cursor-pointer">{id}</span>
      </div>
      <div className="client-request-lower-main-container flex-1 p-16 flex-column gap-24">
        <div className="client-detail-main-container xetgo-font-tag">
          <div className="client-detail-grid ">
            <p className="client-name flex-row gap-12 py-18 px-12 align-items-center">
              <span className="bold"> {clientFormData?.company_name}</span>
              <span className="uuid px-12 py-6">{clientFormData?.uuid}</span>
            </p>
            <p className="client-gst flex-row gap-12 py-18 px-12 align-items-center bolder">
              {clientFormData?.gst}
            </p>
            <div className="flex-row align-items-center p-12 justify-content-space-between align-items-center gap-20">
              <p className="client-poc flex-row gap-4">
                <span className="bold"> {clientFormData?.poc_detail.name}</span>
                <span className="poc-number">
                  Mob:{clientFormData?.poc_detail.phone_number}
                </span>
              </p>
              <div className="actionables flex-row gap-12">
                {clientFormData?.status === "REQUEST_RECEIVED" && (
                  <>
                    <button
                      onClick={() => handleStatusUpdate("INFORMATION_PENDING")}
                      className="action-btn approve-btn py-8 px-24 flex-row align-items-center justify-content-center gap-10 cursor-pointer bold xetgo-font-tag"
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => handleStatusUpdate("DECLINED")}
                      className="action-btn submit-client-review-btn py-8 px-24 flex-row align-items-center justify-content-center gap-10 cursor-pointer bold xetgo-font-tag"
                    >
                      Decline
                    </button>
                  </>
                )}
                {(clientFormData?.status === "INFORMATION_PENDING" ||
                  clientFormData?.status === "UPDATE_REQUESTED") && (
                  <>
                    <button
                      onClick={handleSaveDetails}
                      className="action-btn save-detail-btn py-8 px-24 flex-row align-items-center justify-content-center gap-10 cursor-pointer bold xetgo-font-tag"
                    >
                      Save details
                    </button>
                    <button
                      onClick={() => handleStatusUpdate("VERIFICATION_PENDING")}
                      className="action-btn submit-client-review-btn py-8 px-24 flex-row align-items-center justify-content-center gap-10 cursor-pointer bold xetgo-font-tag"
                    >
                      Submit for Review
                    </button>
                  </>
                )}
                {clientFormData?.status === "VERIFICATION_PENDING" && (
                  <button className="action-btn verification-pending-btn py-8 px-32 flex-row align-items-center justify-content-center bold xetgo-font-tag">
                    Verification Pending
                  </button>
                )}
                {clientFormData?.status === "APPROVED" && (
                  <button className="action-btn save-detail-btn py-8 px-32 flex-row align-items-center justify-content-center bold xetgo-font-tag">
                    Approved
                  </button>
                )}
                {clientFormData?.status === "REJECTED" && (
                  <button className="action-btn submit-client-review-btn py-8 px-32 flex-row align-items-center justify-content-center bold xetgo-font-tag">
                    Rejected
                  </button>
                )}
                {clientFormData?.status === "DECLINED" && (
                  <button className="action-btn submit-client-review-btn py-8 px-32 flex-row align-items-center justify-content-center bold xetgo-font-tag">
                    Declined
                  </button>
                )}
              </div>
            </div>
          </div>
          <div style={{ color: "#8F8F8F" }} className="p-12">
            <p>{clientFormData?.address.address_line}</p>
            <p>
              {clientFormData?.address.city}, {clientFormData?.address.state},
              {clientFormData?.address.pincode}.
            </p>
          </div>
        </div>
        {clientFormData?.questions && clientFormData.questions.length > 0 && (
          <div
            className="p-12"
            style={{
              backgroundColor: "#fff6ee",
              boxSizing: "border-box",
              borderRadius: "8px",
            }}
          >
            <div className="gap-12 question-list-container p-2 border-box">
              {clientFormData.questions.map((question, index) => (
                <CustomQuestionForm
                  key={index}
                  questionDetails={question}
                  onValueChange={onQuestionResponseCapture}
                  disabled={clientFormData.status === "APPROVED"}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ClientRequestFormDetail;
