import { useNavigate } from "react-router-dom";
import { IAuctionItem } from "../../models/Auction";
import { formatDateTime } from "../../utils/Helper";
import "./AuctionListCard.scss";
import React, { useEffect, useState } from "react";
interface AuctionListCardProps {
  type: string;
  detail: IAuctionItem;
}

const AuctionListCard: React.FC<AuctionListCardProps> = ({ type, detail }) => {
  const [timeLeft, setTimeLeft] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    updateTimer();
    const intervalId = setInterval(updateTimer, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const updateTimer = () => {
    const endTime = new Date(detail.end_time).getTime();
    const now = new Date().getTime();
    const timeDifference = endTime - now;

    if (timeDifference > 0) {
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      setTimeLeft(`${days}d ${hours}h ${minutes}m Left`);
    } else {
      setTimeLeft("Auction Ended");
    }
  };

  const handleToggleFavourite = () => {
    console.log("handle toggling ");
  };
  return (
    <div
      className="auction-list-card-main-container p-8"
      onClick={() => navigate(`/auctions/${detail.id}`)}
    >
      <div
        className={`auction-card-detail-container flex-column gap-18 px-14 py-12 ${
          type == "LIVE" && "live-auction-detail-container"
        }  ${type == "UPCOMING" && "upcoming-auction-detail-container"}`}
      >
        <div className="flex-row align-items-center justify-content-space-between">
          <img
            height={22}
            width={22}
            onClick={handleToggleFavourite}
            className="cursor-pointer"
            src={
              detail.favourite
                ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241023140817-star.svg"
                : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241023140845-star.svg"
            }
            alt="fav-icon"
          />
          <div className="flex-row gap-12 align-items-center">
            <a
              href={detail.guidelines}
              target="_blank"
              rel="noreferrer"
              style={{ height: "16px" }}
            >
              <img
                height={16}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240904094334-download_purple.svg"
                alt="download-icon"
              />
            </a>
            {type == "UPCOMING" && (
              <img
                height={20}
                width={20}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241023141252-Group+300.svg"
                alt="edit-icon"
              />
            )}
            {type == "LIVE" && (
              <p className="active-time xetgo-font-tag">{timeLeft}</p>
            )}
          </div>
        </div>
        <div className="flex-column gap-27">
          <p className="xetgo-font-button">{detail.title}</p>
          <p
            className={`auction-title-lower-bar ${
              type === "LIVE" && "live-auction-title-lower-bar"
            } ${type === "UPCOMING" && "upcoming-auction-title-lower-bar"}`}
          ></p>
          <div className="flex-column gap-8">
            <div className="flex-row align-items-center justify-content-space-between gap-20 xetgo-font-tag">
              <p className="bold flex-1">Auction ID</p>
              <p className="auction-sub-title bold flex-1">#{detail.id}</p>
            </div>
            <div className="flex-row align-items-center justify-content-space-between gap-20 xetgo-font-tag">
              <p className="bold flex-1">Auction type</p>
              <p className="auction-sub-title bold flex-1">{detail.type}</p>
            </div>
            <div className="flex-row align-items-center justify-content-space-between gap-20 xetgo-font-tag">
              <p className="bold flex-1">Start date & time</p>
              <p className="auction-sub-title bold flex-1">
                {formatDateTime(detail.start_time)}
              </p>
            </div>
            <div className="flex-row align-items-center justify-content-space-between gap-20 xetgo-font-tag">
              <p className="bold flex-1">End date & time</p>
              <p className="auction-sub-title bold flex-1">
                {formatDateTime(detail.end_time)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuctionListCard;
