import "./BidHallTermsAndCondition.scss";
import React, { useState } from "react";

interface TandCProps {
  handleDecline: () => void;
  handleSubmit: () => void;
}

const BidHallTermsAndCondition: React.FC<TandCProps> = ({
  handleDecline,
  handleSubmit,
}) => {
  const SAMPLE_TEST_DATA = [
    "We have thoroughly read, examined, and fully understood the Auction Documents, including all instructions, terms, conditions, and specifications related to this Reverse Auction notice, and we have no reservations or ambiguities regarding the same.",
    "We undertake to execute the works in full compliance with the terms and conditions outlined in the Auction Documents and in accordance with the standards and timelines specified therein.",
    " Our bid represents a firm and binding commitment and will remain valid for the period specified in the Auction Documents. We understand that withdrawal of our bid during the validity period may result in penalties as specified in the Auction Documents.",
    " We accept that the organizing party reserves the right to reject, evaluate, or select any bid at their discretion, without being obligated to accept the lowest bid or any bid received, in alignment with the evaluation criteria provided in the Auction Documents.",
    " We acknowledge that participation in this Reverse Auction implies adherence to all applicable legal, ethical, and compliance standards and agree to abide by any additional instructions issued during the auction process.",
    "We understand that any deviation from the agreed terms or failure to comply with the Auction Documents may lead to disqualification or forfeiture of our bid.",
  ];
  const [checked, setChecked] = useState(false);

  const handleAgreed = () => {
    if (!checked) {
      return;
    } else {
      console.log("checked please let the parent component know");
      handleSubmit();
    }
  };

  return (
    <div className="terms-condition-main-page full-width flex-row align-items-center justify-content-center">
      <div className="terms-condition-popup-main-container flex-column gap-48 p-16">
        <div style={{ color: "#4A4A4A" }} className="flex-column gap-14">
          <div className="declaration-main-container flex-row align-items-center justify-content-space-between xetgo-font-tag bold">
            <p>We, hereby declare and confirm that:</p>
            <img
              className="cursor-pointer"
              onClick={handleDecline}
              height={16}
              width={16}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012084858-close.svg"
              alt="close-icon"
            />
          </div>
          <div className="all-conditions-container xetgo-font-tag flex-column gap-12">
            {SAMPLE_TEST_DATA.map((term, index) => (
              <div key={index} className=" flex-row gap-2">
                <span className="bold">{index + 1}.</span> {term}
              </div>
            ))}
          </div>
        </div>
        <div className="t-and-c-lower-main-container flex-column">
          <div className="flex-row gap-12 align-items-center">
            <p
              onClick={() => setChecked(!checked)}
              className={`checkbox cursor-pointer ${
                checked && "checked-box-filled"
              }`}
            ></p>
            <p
              style={{ color: "#8D8D8D" }}
              className="flex-1  xetgo-font-tag bold"
            >
              By agreeing to participate in this Reverse Auction, we confirm our
              full commitment to the process and to executing the works as per
              the requirements of the Auction Documents.
            </p>
          </div>
          <div className="t-c-btn-main-container flex-row gap-12 align-items-center justify-content-center  xetgo-font-tag">
            <p
              onClick={handleDecline}
              className="decline-btn flex-row align-items-center justify-content-center cursor-pointer border-box py-7"
            >
              Decline
            </p>
            <p
              onClick={handleAgreed}
              className={`agree-btn flex-row align-items-center justify-content-center border-box py-7 ${
                checked && "cursor-pointer"
              }`}
            >
              Agree
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BidHallTermsAndCondition;
