import React, { useEffect, useState } from "react";
import "./CustomQuestionForm.scss";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { OptionsSchema, QuestionDetail } from "../../models/ClientRequestForm";

interface CustormQuestionProps {
  questionDetails: QuestionDetail;
  onValueChange: (
    question: QuestionDetail,
    response: string[] | File[] | OptionsSchema[]
  ) => void;
  disabled: boolean;
}

const CustomQuestionForm: React.FC<CustormQuestionProps> = ({
  questionDetails,
  onValueChange,
  disabled,
}) => {
  const [question, setQuestion] = useState<QuestionDetail>(questionDetails);
  const [textAndNumberResponse, setTextAndNumberResponse] = useState(
    question.response[0] || ""
  );
  const [date, setDate] = useState(question.response[0] || "");

  const updateQuestionOptions = (updatedOptions: OptionsSchema[]) => {
    setQuestion((prev) => ({
      ...prev,
      options: updatedOptions,
    }));
    console.log("Ques:", question.id, updatedOptions);
    onValueChange(question, updatedOptions);
  };

  const handleFileChange = (
    type: string,
    question: QuestionDetail,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (type === "file_upload") {
      handleSingleFileChange(question, event);
    } else {
      handleMultipleFileChange(question, event);
    }
  };

  const handleSingleFileChange = (
    question: QuestionDetail,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const options = question.options.map((opt) => {
        return { ...opt, checked: false };
      });
      const updatedOptions = [
        ...options,
        {
          id: new Date().getTime(),
          name: file.name,
          checked: true,
        },
      ];
      updateQuestionOptions(updatedOptions);
      onValueChange(question, [file] as File[]);
    }
  };

  const handleMultipleFileChange = (
    question: QuestionDetail,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      const updatedOptions = [...question.options];
      files.forEach((file, index) => {
        updatedOptions.push({
          id: new Date().getTime() + index,
          name: file.name,
          checked: true,
        });
      });
      updateQuestionOptions(updatedOptions);
      onValueChange(question, files);
    }
  };

  const handleDateChange = (
    question: QuestionDetail,
    currentDate: string | undefined
  ) => {
    if (currentDate) {
      setDate(currentDate);
      onValueChange(question, [currentDate]);
    }
  };

  const handleTextAndNumberChange = (
    question: QuestionDetail,
    response: string
  ) => {
    setTextAndNumberResponse(response);
    onValueChange(question, [response]);
  };

  const handleRadioChange = (
    question: QuestionDetail,
    selectedOptionName: string
  ) => {
    const updatedOptions = question.options.map((opt) => ({
      ...opt,
      checked: opt.name === selectedOptionName,
    }));

    updateQuestionOptions(updatedOptions);
    onValueChange(question, [selectedOptionName]);
  };

  const handleCheckboxChange = (
    question: QuestionDetail,
    optionIndex: number
  ) => {
    const updatedOptions = [...question.options];
    updatedOptions[optionIndex].checked = !updatedOptions[optionIndex].checked;
    updateQuestionOptions(updatedOptions);
  };

  const fileName = (name: string) => {
    return name.substring(name.indexOf("-") + 1, name.length);
  };

  const deleteResponseAttachment = (
    question: QuestionDetail,
    response: OptionsSchema
  ) => {
    const updatedOptions = question.options.map((opt) => {
      if (opt.id === response.id) {
        opt.checked = false;
      }
      return opt;
    });
    updateQuestionOptions(updatedOptions);
  };

  return (
    <div>
      {question.input_type === "text" && (
        <div className="basic-option-container flex-column gap-8 xetgo-font-tag">
          <p style={{ color: "#1F2937" }} className="bold">
            {question.question}
          </p>
          <input
            onChange={(e) =>
              handleTextAndNumberChange(question, e.target.value)
            }
            className="option-input py-8 px-12"
            value={textAndNumberResponse}
            placeholder="Answer here ..."
            disabled={disabled}
          />
        </div>
      )}

      {question.input_type === "number" && (
        <div className="basic-option-container flex-column gap-8 xetgo-font-tag">
          <p style={{ color: "#1F2937" }} className="bold">
            {question.question}
          </p>
          <input
            onChange={(e) =>
              handleTextAndNumberChange(question, e.target.value)
            }
            className="option-input py-8 px-12"
            value={textAndNumberResponse}
            placeholder="Answer here ..."
            disabled={disabled}
          />
        </div>
      )}

      {question && question.input_type === "radio_button" && (
        <div className="flex-column gap-8 xetgo-font-tag basic-option-container">
          <p style={{ color: "#1F2937" }} className="bold">
            {question.question}
          </p>

          {question.options.length > 0 && (
            <div className="all-options-container flex-column gap-8">
              {question.options.map((option, optionIndex) => (
                <div
                  className="checkbox-main-container flex-row align-items-center gap-8 px-12 py-8 xetgo-font-tag cursor-pointer"
                  key={optionIndex}
                  onClick={() =>
                    disabled ? null : handleRadioChange(question, option.name)
                  }
                >
                  <input
                    className="cursor-pointer custom-radio"
                    type="radio"
                    name={`radio-${question.id}`}
                    checked={option.checked}
                    onChange={() => handleRadioChange(question, option.name)}
                    disabled={disabled}
                  />
                  <p className="bold">{option.name}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {question && question.input_type === "checkbox" && (
        <div className="flex-column gap-8 xetgo-font-tag basic-option-container">
          <p style={{ color: "#1F2937" }} className="bold">
            {question.question}
          </p>

          {question.options.length > 0 && (
            <div className="all-options-container flex-column gap-8">
              {question.options.map((option, optionIndex) => (
                <div
                  className="checkbox-main-container flex-row align-items-center gap-8 px-12 py-8 xetgo-font-tag cursor-pointer"
                  key={optionIndex}
                  onClick={() =>
                    disabled
                      ? null
                      : handleCheckboxChange(question, optionIndex)
                  }
                >
                  <input
                    className="cursor-pointer custom-check-input"
                    type="checkbox"
                    checked={option.checked}
                    onChange={() => handleCheckboxChange(question, optionIndex)}
                    disabled={disabled}
                  />
                  <p className="bold">{option.name}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {question.input_type === "datetime" && question.response && (
        <div className="basic-option-container flex-column gap-8 xetgo-font-tag">
          <p style={{ color: "#1F2937" }} className="bold">
            {question.question}
          </p>
          <div className="date-main-container">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="option-input py-8 px-12  xetgo-font-tag full-width"
                value={dayjs(date || "".split("-").reverse().join("-"))}
                format={"DD-MM-YYYY"}
                views={["year", "month", "day"]}
                onChange={(event) =>
                  handleDateChange(question, event?.toDate().toISOString())
                }
                disabled={disabled}
              />
            </LocalizationProvider>
          </div>
        </div>
      )}

      {(question.input_type === "multi_file_upload" ||
        question.input_type === "file_upload") && (
        <div className="basic-option-container flex-column gap-8 flex-1">
          <p className="xetgo-font-tag bold" style={{ color: "#1F2937" }}>
            {question.question}
          </p>
          <input
            onChange={(event) => {
              handleFileChange(question.input_type, question, event);
            }}
            id={`multi-fileInput-${question.id}`}
            name="file"
            type="File"
            style={{
              position: "absolute",
              top: "12px",
              right: "12px",
              display: "none",
            }}
            multiple={question.input_type === "multi_file_upload"}
            disabled={disabled}
          />
          <label
            htmlFor={`multi-fileInput-${question.id}`}
            className="option-input file-label px-12 py-8 flex-row align-items-center cursor-pointer"
          >
            <p className="xetgo-font-tag flex-row align-items-center justify-content-space-between full-width flex-wrap">
              Choose file
              <img
                className="upload-icon"
                height={16}
                width={16}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240714075812-upload+%286%29.svg"
                alt="upload-icon"
              />
            </p>
          </label>

          {question.options &&
            question.options.filter((opt) => opt.checked).length > 0 && (
              <div className="flex-row align-items-center gap-10 flex-wrap">
                {question.options
                  .filter((opt) => opt.checked)
                  .map((option, rIndex) => {
                    return (
                      <div
                        className="attachment-pill py-4 px-8 flex-row gap-10 align-items-center"
                        key={rIndex}
                      >
                        <p
                          onClick={() => window.open(option.name, "_blank")}
                          className="xetgo-font-tag bold cursor-pointer"
                        >
                          {fileName(option.name)}
                        </p>
                        <img
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624162818-close+2.svg"
                          width={8}
                          height={8}
                          alt="close"
                          className="cursor-pointer"
                          onClick={() =>
                            deleteResponseAttachment(question, option)
                          }
                        />
                      </div>
                    );
                  })}
              </div>
            )}
        </div>
      )}
    </div>
  );
};
export default CustomQuestionForm;
