import { OptionListSchema } from "../models/OptionListSchema";

export const SIDEBAR_DATA = [
  {
    name: "SKU",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240417144546-list-outline.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240417144546-list-outline.svg",
    tabName: "sku",
    redirectionUrl: "/skus",
    options: [],
  },
  {
    name: "Auction",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241023145142-Group+1171276134.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241023145142-Group+1171276134.svg",
    tabName: "auction",
    redirectionUrl: "/auctions",
    options: [],
  },
  {
    name: "Quote",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240131090019-cube-outline.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240212092652-cube-white.svg",
    tabName: "quotes",
    options: [],
    redirectionUrl: "/quotes",
  },
  {
    name: "Purchase Order",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240131090225-cart-outline.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240212092652-cart-white.svg",
    tabName: "purchaseOrder",
    redirectionUrl: "/purchase-orders",
    options: [],
  },
  {
    name: "Delivery Calendar",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516052108-calendar.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516052108-calendar.svg",
    tabName: "deliveryCalendar",
    options: [],
    redirectionUrl: "/delivery-calendar",
  },
  {
    name: "Quality Check",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240730112901-traffic-cone.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240730112901-traffic-cone.svg",
    tabName: "qualityCheck",
    redirectionUrl: "/quality-checks",
    options: [],
  },
  {
    name: "Negotiations",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241225233050-indian-rupee.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241225233050-indian-rupee.svg",
    tabName: "negotiations",
    redirectionUrl: "/negotiations",
    options: [],
  },
];
