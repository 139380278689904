import React, { useEffect, useState } from "react";
import { Auction } from "../../models/Auction";
import "./AuctionDetailInfo.scss";
import { commaFormat, formatDateTime } from "../../utils/Helper";
import { useToast } from "../../hooks/useToast";

interface AuctionDetailInfoProps {
  auction: Auction;
}

const AuctionDetailInfo: React.FC<AuctionDetailInfoProps> = ({ auction }) => {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const updateTimer = () => {
      if (auction) {
        const endTime = new Date(auction.end_time).getTime();
        const now = new Date().getTime();
        const timeDifference = endTime - now;

        if (timeDifference > 0) {
          const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
          );
          setTimeLeft(`${days}d ${hours}h ${minutes}m Left`);
        } else {
          setTimeLeft("Auction Ended");
        }
      }
    };
    updateTimer();
    const intervalId = setInterval(updateTimer, 60000);
    return () => clearInterval(intervalId);
  }, [auction]);

  return (
    <div className="flex-column gap-12">
      <div className="auction-detail-wrapper-container p-12 flex-column gap-12">
        <div className="type-tag xetgo-font-tag bold px-28 py-6">
          {auction.visibility}
        </div>
        <div
          className="flex-row gap-16 justify-content-flex-end"
          style={{ marginTop: "16px" }}
        >
          <div className="flex-row gap-8">
            <p className="xetgo-font-tag" style={{ color: "#303745" }}>
              Starts on:
            </p>
            <p
              className="xetgo-font-tag"
              style={{ color: "#747474", fontStyle: "italic" }}
            >
              {formatDateTime(auction.start_time)}
            </p>
          </div>
          <div className="flex-row gap-8">
            <p className="xetgo-font-tag">End on:</p>
            <p
              className="xetgo-font-tag"
              style={{ color: "#747474", fontStyle: "italic" }}
            >
              {formatDateTime(auction.end_time)}
            </p>
          </div>
        </div>
        <div className="detail-upper-main-container flex-column gap-2">
          <div className="flex-row justify-content-space-between">
            <p className="heading-bar xetgo-font-button bold p-12">
              {auction.title}
            </p>
            <p className="heading-bar xetgo-font-button bold p-12">
              {timeLeft !== "Auction Ended" ? "Live in: " : ""}
              {timeLeft}
            </p>
          </div>
          <div
            className="detail-description xetgo-font-tag bold p-12"
            dangerouslySetInnerHTML={{
              __html: auction.description,
            }}
          ></div>
        </div>
        <div className="detail-upper-main-container flex-column gap-2">
          <p className="heading-bar xetgo-font-button bold p-12">
            Buyer Details
          </p>
          <div className="flex-row justify-content-space-between detail-description p-12">
            <div className="flex-column xetgo-font-tag gap-8">
              <p className="detail-title flex-1">Contact Number:</p>
              <p className="detail-subtitle bold flex-1 bold">
                {auction.buyer_details.contact}
              </p>
            </div>
            <div className="flex-column xetgo-font-tag gap-8">
              <p className="detail-title flex-1">Person Name:</p>
              <p className="detail-subtitle bold flex-1 bold">
                {auction.buyer_details.name}
              </p>
            </div>
            <div className="flex-column xetgo-font-tag gap-8">
              <p className="detail-title flex-1">Email:</p>
              <p className="detail-subtitle bold flex-1 bold">
                {auction.buyer_details.email}
              </p>
            </div>
            <div className="flex-column xetgo-font-tag gap-8">
              <p className="detail-title flex-1">Location:</p>
              <p className="detail-subtitle bold flex-1 bold">
                {auction.buyer_details.location}
              </p>
            </div>
          </div>
        </div>
        <div className="detail-upper-sku-container p-12 flex-column gap-12">
          <p className="xetgo-font-button bold" style={{ color: "#333333" }}>
            Address & SKUs
          </p>
          {auction.address.length > 0 &&
            auction.address.map((row, index) => (
              <div key={index} className="address-sku-wrapper-container">
                <div className="heading-bar flex-row align-items-center gap-18 xetgo-font-tag p-8">
                  <p className="state-name bold">{row.detail.state}</p>
                  <p style={{ color: "#919191" }}>
                    {row.detail.line}, {row.detail.pincode}
                  </p>
                </div>
                <div className="address-skus-main-container">
                  <div className="address-sku-grid heading-bar-container xetgo-font-tag">
                    <p className="empty-index p-12"></p>
                    <p className="p-12 bold">SKU Name</p>
                    <p className="p-12 bold">Quantity</p>
                    <p className="p-12 bold">Start Price (INR)</p>
                    <p className="p-12 bold">Minimum Decrement Price (INR)</p>
                    <p className="p-12 bold">Description</p>
                  </div>
                  {row.skus.length > 0 &&
                    row.skus.map((sku, skuIndex) => (
                      <div
                        key={skuIndex}
                        className="address-sku-grid xetgo-font-tag"
                      >
                        <p className="index px-12 py-8 flex-row align-items-center justify-content-center">
                          {index + 1}
                        </p>
                        <a
                          href={`/skus/${sku.id}`}
                          className="sku-name px-12 py-8 border-right-top bold flex-row align-items-center"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {sku.name}
                        </a>
                        <p className="border-right-top px-12 py-8 flex-row align-items-center gap-4">
                          {sku.quantity}
                          <span className="measurement-unit">{sku.unit}</span>
                        </p>
                        <p className="start-price border-right-top px-12 py-8 flex-row align-items-center gap-20">
                          ₹
                          <span className="value">
                            {commaFormat(sku.start_price.toString())}
                          </span>
                        </p>
                        <p className="decrement-price border-right-top px-12 py-8 flex-row align-items-center gap-20">
                          ₹<span>{sku.decrement_price}</span>
                        </p>
                        <p className="description-sku border-top px-12 py-8 bold">
                          {sku.description}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AuctionDetailInfo;
