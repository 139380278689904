import { QuestionDetail } from "./ClientRequestForm";
import { CommentFileSchema } from "./Quote";

export interface Auction {
  title: string;
  t_c: boolean;
  type: string;
  auction_id: string;
  auction_status: "UPCOMING" | "LIVE" | "PAST";
  start_time: string;
  end_time: string;
  visibility: string;
  currency: string;
  guidelines: string;
  description: string;
  notes: string;
  buyer_details: {
    contact: number;
    name: string;
    location: string;
    email: string;
  };
  status: string;
  address: AuctionAddress[];
  form: AuctionVendorFormDataSchema;
  comments: {
    comment: string;
    id: number;
    created_at: string;
    name: string;
    avatar: string;
    user_id: number;
    files: CommentFileSchema[];
  }[];
  invite_id: number;
  client: {
    name: string;
    avatar: string;
    id: number;
  };
}

export interface IAuctionItem {
  id: number;
  title: string;
  type: string;
  start_time: string;
  end_time: string;
  favourite: boolean;
  guidelines: string;
}

export interface AuctionAddress {
  detail: {
    state: string;
    pincode: string;
    line: string;
  };
  skus: {
    id: number;
    unit: string;
    name: string;
    quantity: number;
    start_price: number;
    decrement_price: number;
    description: string;
  }[];
}

export const AuctionVendorInvitationStatus: {
  [key: string]: { background: string; color: string; border: string };
} = {
  INVITED: {
    color: "#5151EC",
    background: "#ECECFE",
    border: "#DADAFF",
  },
  ACCEPTED: {
    color: "#D28409",
    background: "#ECECFE",
    border: "#F8F3C5 ",
  },
  PENDING_APPROVAL: {
    color: "#D28409",
    background: "#ECECFE",
    border: "#F8F3C5 ",
  },
  DECLINED: {
    color: "#B93100",
    background: "#FFE0E0",
    border: "#FFC1C1",
  },
  REJECTED: {
    color: "#B93100",
    background: "#FFE0E0",
    border: "#FFC1C1",
  },
  EDIT_REQUESTED: {
    color: "#AA5200",
    background: "#FFECDA",
    border: "#FBDEC4",
  },
  APPROVED: {
    color: "#05AB3A",
    background: "#E5FEED",
    border: "#B8FFD0",
  },
};

export interface AuctionVendorFormDataSchema {
  id: number;
  title: string;
  description: string;
  questions: QuestionDetail[];
  status: string;
}

export interface IAuctionBid {
  bid_rank: number;
  id: number;
  price: number;
  status: string;
  remark: string;
  created_at: string;
}

export interface BidMapResponse {
  [key: number]: BidMapAddress;
}

export interface BidMapAddress {
  name: string;
  state: string;
  skus: {
    bids: IAuctionBid[];
    id: number;
    name: string;
    quantity: number;
    start_price: number;
    decreement: number;
    unit: string;
  }[];
}

export const AuctionBidStatus: {
  [key: string]: {
    background: string;
    color: string;
    border: string;
    name: string;
  };
} = {
  ACCEPTED: {
    color: "#319713",
    background: "#f3faed",
    border: "#e6fddb ",
    name: "Accepted",
  },
  DECLINED: {
    color: "#b93100",
    background: "#ffe0e0",
    border: "#ffc1c1",
    name: "Declined",
  },
  REJECTED: {
    color: "#e0892d",
    background: "#fef0e0",
    border: "#fee5cb",
    name: "Rejected",
  },
};
